.banner {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    var(--color-red) 0%,
    var(--color-burgundy) 100%
  );
}

.banner__four {
  position: absolute;
  top: -60px;
  right: -120px;

  @include media-lg {
    top: -210px;
    right: -250px;
    width: 1200px;
  }
}

.banner__radial-light {
  position: absolute;
  top: -360px;
  right: -120px;
  display: block;
  width: 1430px;
  height: 1200px;
  animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(
    circle,
    rgba(255, 36, 104, 1) 0%,
    rgba(238, 10, 81, 1) 26%,
    rgba(246, 40, 46, 0) 70%
  );
  opacity: 0.8;
  pointer-events: none;
  transform: scale(1.5);
}

.banner__radial-dark {
  position: absolute;

  top: -360px;
  left: -120px;
  display: block;
  width: 2300px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(
    138deg,
    rgba(115, 1, 60, 0.8309917717) 0%,
    rgba(143, 17, 64, 0.7553615196) 24%,
    rgba(246, 40, 46, 0) 140%
  );
  pointer-events: none;
}

.banner__inner {
  z-index: 9;
  max-width: 350px;
  padding: var(--spacing-40) 0;
}

.banner__content {
  margin-bottom: var(--spacing-20);
  color: white;
  font-size: var(--font-size-20);
}
