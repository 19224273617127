.content-sidebar {
  display: flex;
  width: 100%;
}

.content-sidebar__inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: var(--spacing-60) 0;
  column-gap: 120px;

  @include media-lg {
    flex-direction: row;
    margin-bottom: 100px;
  }
}

.content-sidebar__content {
  flex: 2 0;
  padding-bottom: var(--spacing-40);
  @include media-lg {
    padding-bottom: 0;
  }
}

.content-sidebar__sidebar {
  display: flex;
  width: 100%;
  min-width: 350px;
  flex: 1 0;
  flex-direction: column;
}
