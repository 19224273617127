.nav {
  height: 60px;
}
// fixed on scroll

.nav__inner {
  position: relative;
  z-index: 9;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    align-items: center;
  }
}

.nav__logo {
  width: 200px;
  @include media-lg {
    width: 318px;
    height: 36px;
  }
}

.nav__toggle {
  padding: 0;
  border: 0;
  margin-left: auto;
  background: none;
  @include media-lg {
    display: none;
  }
}

.nav__menu {
  position: absolute;
  z-index: 9;
  top: 60px;
  left: 0;
  width: 100%;
  padding: var(--spacing-20);
  background: var(--color-black);
  visibility: hidden;

  @include media-lg {
    position: relative;
    z-index: 9;
    top: inherit;
    left: inherit;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    background: white;
    visibility: visible;
  }
}

.dropdown {
  button {
    margin-left: auto;
    background: none;
    border: 0;
    font-size: 0;
    height: 20px;
    width: 30px;
    z-index: 99;
    cursor: pointer;
    position: relative;
    border-radius: 99rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    @include media-lg {
      // margin-left: 5px;
      padding-right: 0;
      width: auto;
      padding-left: 5px;
      margin-left: 5px;
    }

    &:after {
      width: 20px;
      height: 20px;
      background-size: contain;
      // background-image: url("data:image/svg+xml,%0A%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='up_arrow_WO' transform='translate(10.000000, 10.000000) rotate(-180.000000) translate(-10.000000, -10.000000) translate(-0.000000, -0.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='20' height='20'%3E%3C/rect%3E%3Cpolygon id='Path' fill='%23EE0951' fill-rule='nonzero' points='10 7.8 20 17.8 20 12.2 10 2.2 0 12.2 0 17.8'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      // background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='right' transform='translate(6.000000, 4.000000) rotate(-270.000000) translate(-6.000000, -4.000000) translate(2.000000, -2.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) ' points='4 2 10 10 -2 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-ul2atbdmg0-1' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='cross'%3E%3Cmask id='mask-ul2atbdmg0-2' fill='white'%3E%3Cuse xlink:href='%23path-ul2atbdmg0-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_34'%3E%3C/g%3E%3Cg id='Group_56' mask='url(%23mask-ul2atbdmg0-2)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cline x1='12' y1='0' x2='12' y2='24' id='Line_12'%3E%3C/line%3E%3Cline x1='24' y1='12' x2='0' y2='12' id='Line_13'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      @include media-lg {
        width: 11px;
        height: 10px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='right' transform='translate(6.000000, 4.000000) rotate(-270.000000) translate(-6.000000, -4.000000) translate(2.000000, -2.000000)' fill='%2372003c' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) ' points='4 2 10 10 -2 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
      background-repeat: no-repeat;
      content: "";
    }

    &:hover {
      &:after {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-ul2atbdmg0-1' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='cross'%3E%3Cmask id='mask-ul2atbdmg0-2' fill='white'%3E%3Cuse xlink:href='%23path-ul2atbdmg0-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_34'%3E%3C/g%3E%3Cg id='Group_56' mask='url(%23mask-ul2atbdmg0-2)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cline x1='12' y1='0' x2='12' y2='24' id='Line_12'%3E%3C/line%3E%3Cline x1='24' y1='12' x2='0' y2='12' id='Line_13'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        @include media-lg {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='15px' height='10px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='right' transform='translate(6.000000, 4.000000) rotate(-270.000000) translate(-6.000000, -4.000000) translate(2.000000, -2.000000)' fill='%230B0B0B' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) ' points='4 2 10 10 -2 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }

    &.active-dropdown-button {
      &:after {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-heft5_kk71-1' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='cross'%3E%3Cmask id='mask-heft5_kk71-2' fill='white'%3E%3Cuse xlink:href='%23path-heft5_kk71-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_34'%3E%3C/g%3E%3Cg id='Group_56' mask='url(%23mask-heft5_kk71-2)' stroke-width='2'%3E%3Cline x1='12' y1='0' x2='12' y2='24' id='Line_12' stroke='%23191717'%3E%3C/line%3E%3Cline x1='24' y1='12' x2='0' y2='12' id='Line_13' stroke='%23FFFFFF'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
      @include media-lg {
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='15px' height='10px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='right' transform='translate(6.000000, 4.000000) rotate(-270.000000) translate(-6.000000, -4.000000) translate(2.000000, -2.000000)' fill='%230B0B0B' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) ' points='4 2 10 10 -2 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }
}

.nav__links {
  overflow: hidden;
  padding: 0;
  list-style: none;

  @include media-lg {
    display: flex;
    overflow: visible;
    // justify-content: end;
    justify-content: flex-end;
    padding: 0;
    list-style: none;
  }

  li {
    position: relative;
    @include media-lg {
      margin-right: var(--spacing-30);
    }

    // dropdown

    ul {
      position: relative;
      top: inherit;
      height: 0;
      background: var(--color-black);
      list-style: none;
      visibility: hidden;
      padding-left: 15px;

      @include media-lg {
        position: absolute;
        top: 37px; 
        width: auto;
        padding: var(--spacing-10) var(--spacing-20);
        background: var(--color-black);
        border-radius: 20px;
        box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.26);
        list-style: none;
        visibility: hidden;

        left: 50%;
        transform: translate(-50%, 0);
        

        &:before {
          pointer-events: none;
          position: absolute;
          top: -27px;
          width: 0;
          height: 0;
          border-width: 14px;
          border-style: solid;
          border-color: var(--color-black) transparent transparent transparent;
          content: "";
          transform: rotate(180deg);

          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }

        li {
          a {
            padding: 13px 0;
            white-space: nowrap;
          }

          &:first-child {
            // margin-top: 15px;
          }
        }

        a {
          display: block;
          width: 100%;
          padding: 5px;
          // margin-bottom: 15px;
          color: #fff;

          &:hover {
            color: #c5c5c5;
            @include media-lg {
              &:hover {
                &:after {
                  background-image: none;
                }
              }
            }
          }
        }
        // next level

        ul {
          &:after {
            display: none;
          }
        }
      }

      // always show on desktop

      &.visible {
        ul {
          @include media-lg {
            position: relative;
            top: 0;
            // left: 0;
            width: 100%;
            height: auto;
            padding: 0 0 0 20px;
            box-shadow: none;
            visibility: visible;
          }
        }
      }

      // second level on desktop

      .dropdown {
        @include media-lg {
          // pointer-events: none;
        }

        &:after {
          @include media-lg {
            right: -15px;
            width: 15px;
            height: 2px;
            background-image: none;
          }
        }

        button {
          @include media-lg {
            display: none;
          }
        }
      }
    }
  }

  a {
    color: white;
    //font-size: var(--font-size-14);
    font-size: var(--font-size-25);
    font-weight: 600;
    line-height: 4rem;
    text-decoration: none;
    @include media-lg {
      color: var(--color-burgundy);
      font-size: var(--font-size-16);
      line-height: 1rem;

      &.is-active {
        position: relative;
        color: var(--color-black);

        &:before {
          position: absolute;
          bottom: -22px;
          left: 0;
          width: 100%;
          height: 4px;
          background: var(--color-black);
          content: "";
        }
      }

      &:hover {
        color: var(--color-black);
      }

      &.dropdown {
        &:hover {
          /* 
          &:after {
            background:pink;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='6px' viewBox='0 0 8 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Polygon-16' fill='%23000000' fill-rule='nonzero'%3E%3Cpolygon id='Polygon_16' transform='translate(4.000000, 2.666500) rotate(180.000000) translate(-4.000000, -2.666500) ' points='4 4.4408921e-16 8 5.333 0 5.333'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          } */
        }
      }
    }

    &.dropdown {
      display: flex;
      width: 100%;
      align-items: center;
      white-space: nowrap;

      @include media-lg {
        // padding-right: 15px;

        &:after {
          position: absolute;
          right: 0;
          width: 18px;
          height: 2px;
          background: white;
          content: "";
          transform: scale(1.5);
          margin-left: inherit;
        }

        /* &:after {
          background:green;
          position: absolute;
          right: 0;
          width: 8px;
          height: 6px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='6px' viewBox='0 0 8 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Polygon-16' fill='%23EE0A51' fill-rule='nonzero'%3E%3Cpolygon id='Polygon_16' transform='translate(4.000000, 2.666500) rotate(180.000000) translate(-4.000000, -2.666500) ' points='4 4.4408921e-16 8 5.333 0 5.333'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          background-repeat: no-repeat;
          content: "";
          transform: scale(1.5);
        } */
      }
    }
  }
}

.hamburger .line {
  display: block;
  width: 50px;
  height: 5px;
  margin: 8px auto;
  background-color: var(--color-burgundy);
  transition: all 0.3s ease-in-out;
}

.hamburger {
  transform: scale(0.7);
}

.hamburger:hover {
  cursor: pointer;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
}
