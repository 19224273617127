$default-font-size: 16px;

$default-transition-duration: 0.3s;
$default-transition-function: ease-out;

$breakpoint-2xl: 1536px;
$breakpoint-xl: 1280px;
$breakpoint-lg: 1024px;
$breakpoint-md: 768px;
$breakpoint-sm: 640px;
$breakpoint-xs: 375px;

$indent-col-lg: 30px;
$indent-col-md: 30px;
$indent-col-sm: 20px;
$indent-col-xs: 16px;

$cols-total: 12;

$mockup-width: 1440px;
$container-width: 1170px;

$offset-side: 35px;
$offset-side-md: 30px;
$offset-side-sm: 20px;

$name: '';
$value: '';

$spacing: (
  // 20px
  '10': 10px,
  '20': 20px,
  '30': 30px,
  '40': 40px,
  '60': 60px,
  '80': 80px,
  '100': 100px,
  '120': 120px,
  '140': 140px
);
