/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-cell .image-frame__caption-alt {
  margin-bottom: var(--spacing-40)!important;
  font-size: var(--font-size-16)!important;
  color: #fff !important;
  margin-top: -60px!important;
  margin-left: 15px!important;
  font-weight: 600!important;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  border: none;
  background: hsla(0, 0%, 100%, 0.75);
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  box-shadow: 0 0 0 5px #19f;
  outline: none;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  cursor: auto;
  opacity: 0.3;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */

.flickity-rtl .flickity-prev-next-button.previous {
  right: 10px;
  left: auto;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  bottom: -25px;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 1;
  list-style: none;
  text-align: center;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .flickity-page-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 0;
  // opacity: 0.25;
  border: 1px solid white;
  margin: 0 5px;
  background: transparent;
  border-radius: 99rem;
  cursor: pointer;
  font-size: 0;
}

.flickity-page-dots .flickity-page-dot.is-selected {
  // opacity: 1;
  border: 1px solid var(--color-red);
  background: var(--color-red);
}

// CUSTOM

.carousel {
  //
}

.carousel-cell {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 290px;
  align-items: center;
  justify-content: center;

  div {
    margin-bottom: var(--spacing-40);
    font-size: var(--font-size-16);
    color: #fff !important;
    margin-top: -60px;
    margin-left: 15px;
    font-weight: 600;
  }
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel.is-fullscreen .carousel-cell-image {
  max-width: 100%;
}

.carousel-cell.--alt-caption {
  .image-frame__caption {
    color: #fff !important;
    margin-top: -60px;
    margin-left: 15px;
    font-weight: 600;
  }
}
