.free-collection {
  background: white;
}

.free-collection__inner {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: var(--spacing-60) 0;
  column-gap: 200px;

  @include media-lg {
    // flex-direction: column;
  }
}

.free-collection__left {
  flex: 1 0;
}

.free-collection__right {
  display: none;
  flex: 1 0;
  @include media-lg {
    display: flex;
  }

  .image-frame {
    margin-left: auto;
  }
}

.free-collection__title {
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-40);
}

.free-collection__content {
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-20);
}
