.hero-form {
  position: relative;
  display: flex;
  overflow: hidden;
  background: linear-gradient(180deg, #b60547 0%, #72003c 100%);
  background-color: var(--color-red);
  color: white;
}

.hero-form__four {
  position: absolute;
  top: -60px;
  right: -120px;

  @include media-lg {
    top: -210px;
    right: -250px;
    width: 1200px;
  }
}

.hero-form__radial-light {
  position: absolute;
  top: -360px;
  right: -120px;
  display: block;
  width: 900px;
  height: 900px;
  // animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(circle, rgba(255, 36, 104, 1) 0%, rgba(238, 10, 81, 1) 24%, rgba(246, 40, 46, 0) 64%);
  opacity: 0.6;
  pointer-events: none;
  // transform: scale(1.5);
}

.hero-form__radial-dark {
  position: absolute;

  top: -360px;
  left: -120px;
  display: block;
  width: 810px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(
    138deg,
    rgba(115, 1, 60, 0.8309917717) 0%,
    rgba(143, 17, 64, 0.7553615196) 23%,
    rgba(246, 40, 46, 0) 68%
  );
  pointer-events: none;
}

.hero-form__inner {
  position: relative;
  z-index: 8;
  display: flex;

  flex-direction: column;
  padding: var(--spacing-40) 0;
  gap: 20px;

  max-width: calc(100vw - 40px);
  
  @include media-lg {
    width: 100%;
    flex-direction: row;
    padding: var(--spacing-60) 0;

    gap: 200px;
  }
}

.hero-form__form {
  display: flex;
  flex: 0.9 0;
  flex-direction: column;
  margin-bottom: auto;
}

.hero-form__title {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-50);

  svg {
    height: 220px;
  }

  &.--valuation {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-20);

    &::after {
      content: "No Admin Fees";
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-yellow);
      color: #000;
      font-size: 1.6rem;
      font-weight: 400;
      text-align: center;
      height: 125px;
      width: 125px;
      min-width: 125px;
      border-radius: 999px;
      transform: rotate(10deg);
      word-spacing: 9999px;

      @media (min-width: 768px) {
        font-size: 1.8rem;
        height: 150px;
        width: 150px;
        min-width: 150px;
      }
      
      @media (min-width: 1024px) {
        position: absolute;
        right: -125px;
      }
      
      @media (min-width: 1200px) {
        right: -50px;
      }
    }
  }
  
  

}

.hero-form__description {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-25);
  font-weight: 400;
}

.hero-form__steps {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-bottom: var(--spacing-60);

  margin-left: -25px;
  margin-right: -25px;

  @include media-lg {
    margin-left: -30px;
    padding-right: 30px;
  }
}

.hero-form__steps-icons,
.hero-form__steps-counts {
  display: flex;
  flex: 1 0;
}

.hero-form__step-icon {
  position: relative;
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-20);

  &:before {
    position: absolute;
    right: -10px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ESlice 1%3C/title%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='18' height='15'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-161' transform='translate(-1.999000, 0.306000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_81'%3E%3C/g%3E%3Cg id='Group_138' mask='url(%23mask-2)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cg transform='translate(1.999000, 1.054000)'%3E%3Cpolyline id='Path_44' points='7.943 0 14.583 6.64 7.943 13.28'%3E%3C/polyline%3E%3Cline x1='0' y1='6.64' x2='14.324' y2='6.64' id='Line_16'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
  }

  &:last-of-type {
    &:before {
      display: none;
    }
  }

  span {
    filter: invert(1);
    opacity: 0.4;

    img {
      transform: scale(0.8);
      @include media-lg {
        transform: scale(1);
      }
    }
  }

  &.complete {
    span {
      filter: none;
      opacity: 1;
    }
  }

  &.active {
    &:after {
      position: absolute;
      top: -30px;
      color: white;
      content: url("data:image/svg+xml,%0A%3Csvg width='12px' height='8px' viewBox='0 0 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='highlight_WO' transform='translate(-0.000000, 0.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(6.000000, 4.000000) rotate(180.000000) translate(-6.000000, -4.000000) ' points='6 8.8817842e-16 12 8 8.8817842e-16 8'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.hero-form__step-count {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.hero-form__car-info {
  position: relative;
  width: calc(100% + 40px);
  flex: 1 0;
  margin-bottom: -41px;
  margin-left: -20px;
  @include media-md {
    width: 100%;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.hero-form__car-info-inner {
  position: relative;
  padding: var(--spacing-40) var(--spacing-20);
  background: var(--color-black);
}

.hero-form__car-info-flag {
  position: absolute;
  top: 80px;
  left: -180px;
  width: 290px;
}

.hero-form__your-car {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-40);
}

.hero-form__car-logo {
  width: auto;
  height: 60px;
}

.hero-form__your-car-details {
  display: flex;
  padding-bottom: var(--spacing-40);

  ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0;
    font-size: var(--font-size-20);
    line-height: 2.5rem;
    list-style: none;

    li {
      display: flex;

      span {
        flex: 1 0;

        &:nth-child(even) {
          font-weight: bold;
        }
      }
    }
  }
}

.hero-form__car-wrong-info {
  p {
    max-width: 240px;
    margin-bottom: var(--spacing-20);
  }

  svg {
    margin-right: var(--spacing-10);
  }

  a {
    display: flex;
    align-items: center;
    color: white;
    font-size: var(--font-size-30);
    font-weight: bold;
  }
}

.hero-form__valuation-value-wrapper {
  display: flex;
  .tooltip-right {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.hero-form__valuation-value {
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-50);
}

.hero-form__valuation-accept {
  margin-bottom: var(--spacing-40);
}

.hero-form__valuation-call {
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-20);
  // max-width: 370px;

  a,
  span {
    color: white;
    font-weight: 700;
    text-decoration: none;
  }
}

.hero-form__valuation-how {
  position: relative;
  display: flex;
  padding-left: 20px;
  margin-bottom: var(--spacing-60);
  color: white;
  font-size: var(--font-size-20);

  svg {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 20px;
  }
}

.hero-form__valuation-description {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-25);
  font-weight: 400;
}

.hero-form__accepted {
  display: flex;
}
.hero-form__accepted-content {
  width: 100%;
}
.hero-form__flag {
  position: relative;
  width: 340px;
  padding: 4px;
  @include media-lg {
    display: none;
  }

  img {
    position: absolute;
    right: -30px;
    height: auto;
    width: auto;
    max-width: 500px;
  }
}

#button-continue-mileage:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.valuation_error {
  padding: 1rem; 
  border-radius: 10px;
  background-color: white;
  color: black;
  position: absolute;
  bottom: -15px;
  transform: translateY(100%);
  z-index: 99;
  max-width: 65%;
  font-size: 0.75rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.valuation_error::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  top: -15px;
  left: 30px;
  z-index: -1;
}

.valuation_error.active {
  opacity: 1;
  visibility: visible;
}

.valuation_error svg {
  max-width: 25px;
  fill: orange;
}