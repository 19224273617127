/* :root {
    --color-primary: blue;
    --color-bg-trustpilot: #000032;
} */

@import 'constants';
@import 'colors';
@import 'lib/base';
@import 'lib-external/base';
@import 'fonts';
@import 'form';
@import 'reset';
@import 'global';
@import 'extends';
@import 'utils';

// Custom CSS variables

:root {
  /*
    // Loop over spacing map and create custom CSS variables
    @each $name, $value in $spacing {
        --spacing-#{$name}: #{$value};
    } */

  // Loop over font sizes map create custom CSS variables

  // Loop over color map from _config and create CSS custom props
  @each $name, $value in $colors {
    --color-#{$name}: #{$value};
    // --#{$name}-rgb: #{hexToRGB($value)};
  }

  @each $name, $value in $font-sizes {
    --font-size-#{$name}: #{$value};
  }

  @each $name, $value in $spacing {
    --spacing-#{$name}: #{$value};
  }
}

// Large components
@import '../components/button/button';
@import '../components/content-sidebar/content-sidebar';
@import '../components/container/container';
@import '../components/trustpilot-head/trustpilot-head';
@import '../components/nav/nav';
@import '../components/hero/hero';
@import '../components/hero-simple/hero-simple';
@import '../components/hero-form/hero-form';
@import '../components/calculating/calculating';
@import '../components/trustpilot-carousel/trustpilot-carousel';
@import '../components/how-it-works/how-it-works';
@import '../components/vehicles-covered/vehicles-covered';
@import '../components/valuation-appointments/valuation-appointments';
@import '../components/free-valuations/free-valuations';
@import '../components/free-collection/free-collection';
@import '../components/banner/banner';
@import '../components/faqs/faqs';
@import '../components/area-by-region/area-by-region';
@import '../components/footer/footer';
@import '../components/team-members/team-members.scss';

// Nested components
@import '../components/_breadcrumbs/breadcrumbs';
@import '../components/_opening-times/opening-times';
@import '../components/_content-block-red/content-block-red';
@import '../components/_video/video';

// Pages
@import './pages/index';
@import './pages/article-listings';
@import './pages/article-item';