.free-valuations {
  background: var(--color-black);
  color: white;
}

.free-valuations__inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: var(--spacing-60) 0;

  @include media-lg {
    flex-direction: row;
  }
}

.free-valuations__left {
  display: none;
  flex: 1 0;
  @include media-lg {
    display: flex;
  }
}

.free-valuations__right {
  flex: 1.4 0;
}

.free-valuations__title {
  margin-bottom: var(--spacing-10);
  font-size: var(--font-size-40);
}

.free-valuations__subtitle {
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-25);
  font-weight: 400;
}

.free-valuations__list {
  display: flex;
  flex-direction: column;
}

.free-valuations__list-item {
  position: relative;
  display: flex;
  max-width: 400px;
  padding-left: var(--spacing-60);
  margin-bottom: var(--spacing-40);
  counter-increment: step;
  font-size: var(--font-size-20);

  &:before {
    position: absolute;
    top: 12px;
    left: 20px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-red);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18px' height='16px' viewBox='0 0 18 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='tick_WO' transform='translate(0.939000, 0.682000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group'%3E%3Cpath d='M5.062,15.3040007 C3.99556026,15.3046451 2.97281223,14.8803594 2.22,14.125 L0,11.91 L2.122,9.789 L4.337,12.004 C4.55443746,12.2212233 4.8578052,12.3295268 5.16364872,12.2991168 C5.46949224,12.2687067 5.74559393,12.1027866 5.916,11.847 L13.816,0 L16.316,1.664 L8.416,13.511 C7.74812464,14.5162204 6.66321868,15.1673843 5.462,15.284 C5.32866667,15.2973333 5.19533333,15.3040007 5.062,15.3040007 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 12px;
    border-radius: 99rem;
    content: '';
    font-size: var(--font-size-20);
    font-weight: 800;
    transform: translate(-50%, -50%);
  }
  @include media-lg {
    padding-left: var(--spacing-60);
    margin-bottom: var(--spacing-30);

    &:before {
      width: 24px;
      height: 24px;
      border: 2px solid var(--color-red);
      font-size: var(--font-size-30);
    }
  }
}
