.hero {
  position: relative;
  display: flex;
  overflow: hidden;
  background: linear-gradient(180deg, var(--color-red) 0%, var(--color-burgundy) 100%);
  background-color: var(--color-red);
  color: white;
  min-height: 65vh;

  + .trustpilot-widget {
    padding: var(--spacing-20) 0;
    border-bottom: 1px solid #000;
  }
}

.hero__four {
  position: absolute;
  top: 0;
  right: -50%;
  width: 575px;
  max-width: none;

  @include media-md {
    top: -175px;
    right: 8vw;
    width: 900px;
  }
}

.hero__radial-light {
  position: absolute;
  top: -360px;
  right: -120px;
  display: block;
  width: 900px;
  height: 900px;
  animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(circle, rgba(255, 36, 104, 1) 0%, rgba(238, 10, 81, 1) 24%, rgba(246, 40, 46, 0) 64%);
  opacity: 0.8;
  pointer-events: none;
  transform: scale(1.5);
}

.hero__radial-dark {
  position: absolute;

  top: -360px;
  left: -120px;
  display: block;
  width: 810px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(
    138deg,
    rgba(115, 1, 60, 0.8309917717) 0%,
    rgba(143, 17, 64, 0.7553615196) 23%,
    rgba(246, 40, 46, 0) 68%
  );
  pointer-events: none;
}

.hero__inner {
  display: flex;
  flex: .9 0;
  flex-direction: column;
  padding: var(--spacing-20) 0;

  @include media-md {
    padding: var(--spacing-40) 0;
  }

  @include media-lg {
    padding: var(--spacing-60) 0;
  }
}

.hero__inner-desktop {
  display: none;
  flex: 2 0;
  @include media-lg {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.hero__title {
  position: relative;
  z-index: 1;
  max-width: 100%;
  font-size: var(--font-size-40);
  line-height: .9;
  margin-bottom: var(--spacing-10);

  @include media-md {
    font-size: 4rem;
    margin-bottom: var(--spacing-20);
  }

  img {
    width: auto;
    max-width: 175px;
    height: auto;

    @supports (-webkit-touch-callout: none) {
      max-width: 140px;
    }

    @include media-md {
      max-width: 270px;
    }
  }  
}

.hero__description {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-10);
  font-size: var(--font-size-20);
  font-weight: 400;

  @include media-md {
    margin-bottom: var(--spacing-40);
    font-size: var(--font-size-25);
  }
}

.hero__car {
  position: relative;
  z-index: 1;
  
  margin-left: -20px;
  width: calc(115% + 40px);
  max-width: calc(115% + 40px);

  @supports (-webkit-touch-callout: none) {
    height: 145px;
    width: max-content;
  }

  @include media-md {
    margin-bottom: var(--spacing-20);
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    height: auto;
  }

  @include media-lg {
    display: none;
  }
}

.hero__car-desktop {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  margin-left: 150px;
  bottom: 0;
}

.hero__valuation {
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;
  height: 60px;
  border: 2px solid white;
  margin-bottom: var(--spacing-10);
  background: white;
  border-radius: 8px;
  
  @include media-md {
    margin-bottom: var(--spacing-20);
  }

  &.invalid {
    border: 2px solid var(--color-red);
  }
}

.hero__valuation-country {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px;
  background: var(--color-blue);
  font-weight: 800;
  font-family: 'Roboto Condensed', sans-serif;
}

.hero__valuation-input {
  width: 100%;
  padding: 0 var(--spacing-20);
  border: 0;
  background: none;
  font-size: var(--font-size-30);
  font-weight: bold;
  // text-transform: uppercase;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-black);
    opacity: 1; /* Firefox */
  }
  // ::-ms-input-placeholder

  &.--white {
    background: white;
  }

  &.--yellow {
    background: var(--color-yellow);
  }
}
.hero-form__valuation-tagline {
  font-weight: inherit;
}