.breadcrumbs {
  position: relative;
  display: flex;
  padding: 0!important;
  margin-bottom: var(--spacing-30);
  font-size: 20px;
  font-weight: 600;
  list-style: none;

  li {
    position: relative;
    margin-left: var(--spacing-40);

    &:first-of-type {
      margin: 0;
    }

    &:after {
      position: absolute;
      top: 10px;
      right: -30px;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='12px' viewBox='0 0 8 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='right_WO' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpolygon id='Path' transform='translate(4.000000, 6.000000) rotate(90.000000) translate(-4.000000, -6.000000) ' points='4 2 10 10 -2 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
      color: white;
    }
  }
}
