.team-members {
    background-color: var(--color-white);
    padding: var(--spacing-40);

    &.--light-gradient {
        background-color: var(--color-red);
        background: linear-gradient(180deg, var(--color-red) 0%, var(--color-burgundy) 100%);
        color: #fff;
    }

    &.--dark-gradient {
        background-color: var(--color-burgundy);
        background: linear-gradient(180deg,var(--color-burgundy) 0,#191717 100%);
        color: #fff;
    }
    
    &__title {
        font-size: var(--font-size-40);
        text-align: center;
        margin-bottom: var(--spacing-30);
    }

    &__text {
        margin-bottom: var(--spacing-30);
        font-size: var(--font-size-20);
        text-align: center;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        gap: var(--spacing-40);
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;

        @include media-lg {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.team-member {
    &__image {
        max-width: 100%;
        object-fit: cover;
        margin-bottom: var(--spacing-20);
        aspect-ratio: 1/1;
    }

    &__name {
        font-size: var(--font-size-30);
        margin-bottom: var(--spacing-10);
    }

    &__job-title {
        font-size: var(--font-size-20);
        margin-bottom: var(--spacing-10);
    }
}