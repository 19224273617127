.trustpilot-carousel {
  padding: var(--spacing-40) 0;
  @include media-lg {
    padding: var(--spacing-80) 0;
  }
}
.trustpilot-carousel-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.trustpilot-carousel-title {
  font-size: var(--font-size-25);
  font-weight: 600;
  margin-bottom: var(--spacing-10);
}
.trustpilot-carousel-subtitle {
  font-size: var(--font-size-20);
  font-weight: 400;
  margin-bottom: var(--spacing-40);
}
.trustpilot-carousel-widget {
  width: 100%;
  @include media-lg {
    padding: 0 var(--spacing-80);
  }
}
