.article-item {
    &__hero {
        

        img {
            width: 100%;
            max-height: 65vh;
            object-fit: cover;
        }

        &__title {
            flex-direction: column;
            align-items: baseline;
            gap: var(--spacing-10);
            margin-top: var(--spacing-40);

            h1 {
                font-size: var(--font-size-50);
            }
        }
    }

    &__related {
        flex-direction: column;

        &__title {
            font-size: var(--font-size-35);
            margin-bottom: var(--spacing-20);
        }

        &__items {
            display: grid;
            margin-bottom: var(--spacing-30);
            gap: var(--spacing-20);
            grid-template-columns: 1fr;

            @include min-screen($breakpoint-md) {
                grid-template-columns: repeat(4, 1fr);
            }

            .card-content {
                display: flex;
                flex-direction: column;
                margin-bottom: var(--spacing-20);
                gap: var(--spacing-20);
            }
    
            .card-title {
                color: black;
                font-size: var(--font-size-20);
                text-decoration: none;
                margin-top: var(--spacing-10);
            }
    
            .card-footer a {
                color: #ee0a51;
                font-size: var(--font-size-20);
                font-weight: 800;
                text-decoration: none;
            }

        }
    }
}