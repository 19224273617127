.container-narrow {
  max-width: 940px;
  margin: 0 auto;
}

// display
.hidden {
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
  visibility: hidden;
}

// image frame
.image-frame {
  width: 100%;
  max-width: 350px;
  height: 250px;
  border: 4px solid white;
  margin-bottom: var(--spacing-20);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 50px 0;
  object-fit: cover;
  &.--light {
    border: 4px solid white;
  }

  &.--dark {
    border: 4px solid var(--color-black);
  }
}

.image-frame__caption {
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-16);

  &.--light {
    color: white;
  }

  &.--dark {
    color: var(--color-black);
  }

  &.--alternative {
    color: #fff !important;
    margin-top: -60px;
    margin-left: 15px;
    font-weight: 600;
  }
}

/* 
.image-frame__caption-alt {
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-16);
  color: #fff !important;
  margin-top: -60px;
  margin-left: 15px;
  font-weight: 600;
} */

// modal
.modal {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal__content {
  position: relative;
  width: 100%;
  padding: 20px;
  margin: auto;
  background-color: white;
  color: #000;

  @include media-md {
    width: 500px;
  }

  .hero-form__valuation-accept {
    margin-bottom: var(--spacing-20);
  }
}

.modal__close {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
}

.--mobile-only {
  display: flex;
  @include media-lg {
    display: none;
  }
}

.--desktop-only {
  display: none;
  @include media-lg {
    display: flex;
  }
}

.visible {
  height: auto !important;
  visibility: visible !important;
}

.list-red-checks {
  display: flex;
  max-width: 100%;
  flex-direction: column;

  padding: 0 !important;
  margin-bottom: var(--spacing-20);
  @include media-lg {
    // max-width: 370px;
  }

  li {
    position: relative;
    display: block;
    font-size: var(--font-size-16);
    padding-left: var(--spacing-30);
    margin-bottom: var(--spacing-10);

    &:before {
      position: absolute;
      top: 10px;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg id='Group_348' data-name='Group 348' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Path_57' data-name='Path 57' d='M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z' fill='%23ee0a51'/%3E%3Cg id='Group_200' data-name='Group 200' transform='translate(3.492 3.823)'%3E%3Cpath id='Path_43' data-name='Path 43' d='M13.116,4.413,8.03,12.043a1.627,1.627,0,0,1-2.5.248L4.1,10.865' transform='translate(-4.099 -4.413)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
    }
  }
}

// tooltips
.tooltip {
  margin-bottom: -3px;
  height: 10px;
  width: 10px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  border-radius: 99rem;
  background-color: white;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='2px' height='12px' viewBox='0 0 2 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' font-family='Helvetica' font-size='16' font-weight='normal'%3E%3Cg id='i' transform='translate(-0.777000, -3.000000)' fill='%23EE0A51' fill-rule='nonzero'%3E%3Ctext%3E%3Ctspan x='0' y='15'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

[data-tooltip] {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  position: relative;
  z-index: 9;
}
[data-tooltip]::after {
  background-color: white;
  border-radius: 12px;
  color: var(--color-black);
  content: attr(data-tooltip);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.15rem;
  opacity: 0;
  padding: 20px;
  position: absolute;
  text-transform: none;
  transition: opacity 0.2s;
  visibility: hidden;
  white-space: nowrap;
  z-index: 1;
  margin: 1px;
  white-space: break-spaces;
  width: 260px;
  @include media-lg {
    padding-left: 30px;
  }
}
[data-tooltip].tooltip-top::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: rgba(51, 51, 51, 0.9) transparent transparent transparent;
  top: 0;
  left: 50%;
  margin-left: -0.3rem;
}
[data-tooltip].tooltip-top::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
}
[data-tooltip].tooltip-bottom::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent transparent rgba(51, 51, 51, 0.9) transparent;
  bottom: 0;
  left: 50%;
  margin-left: -0.3rem;
}
[data-tooltip].tooltip-bottom::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%);
}
[data-tooltip].tooltip-left::before {
  border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent transparent transparent rgba(51, 51, 51, 0.9);
  top: 0.3rem;
  right: calc(110% - 0.3rem);
  margin-top: -0.3rem;
}
[data-tooltip].tooltip-left::after {
  top: -0.3rem;
  right: calc(110% + 0.3rem);
}
[data-tooltip].tooltip-right::before {
  opacity: 0;
  // display: none;
  /* border-style: solid;
  border-width: 0.3rem;
  content: "";
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s;
  visibility: hidden;
  border-color: transparent #fff transparent transparent;
  top: 50%;
  top: 0.3rem;
  left: calc(110% - 0.2rem);
  margin-top: -0.3rem; */
  content: "";
  background-image: url("data:image/svg+xml,%0A%3Csvg width='2px' height='12px' viewBox='0 0 2 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' font-family='Helvetica' font-size='16' font-weight='normal'%3E%3Cg id='i' transform='translate(-0.777000, -3.000000)' fill='%23EE0A51' fill-rule='nonzero'%3E%3Ctext%3E%3Ctspan x='0' y='15'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  top: 4px;
  left: -40px;
  width: 10px;
  height: 10px;
  z-index: 4;
  color: black;
}
[data-tooltip].tooltip-right::after {
  top: -0.3rem;
  // left: calc(110% + 0.3rem);
  left: -300%;
}
/* @media (max-width: 767px) { */
@media (max-width: 1300px) {
  [data-tooltip].tooltip-mobile::before {
    display: none;
  }
  [data-tooltip].tooltip-mobile:after {
    font-size: 1rem;
    max-width: 20rem;
    position: fixed;
    bottom: auto;
    // top: 50%;
    top: 30%;
    left: 50%;
    text-align: left;
    transform: translate(-50%);
    white-space: normal;
  }
}
[data-tooltip]:hover::after,
[data-tooltip][class*="tooltip-"]:hover::before {
  visibility: visible;
  opacity: 1;
}

// flex
.flex {
  display: flex;
}

// validation
ul.validation {
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 9;
  position: relative;

  li {
    font-size: var(--font-size-12);
    font-weight: 800;
    margin-bottom: 10px;
    background: #fff;
    padding: 4px 8px;
    color: #ec0750;
    line-height: 1.7;
    position: absolute;
    top: -32px;
    width: 100%;

    display: flex;
    align-items: center;
    border-radius: 2px;

    svg {
      height: 20px;
      margin-right: 7px;
    }
  }
}

//plyr
.plyr__control.plyr__control--overlaid {
  background: var(--color-red) !important;
}

.plyr--full-ui input[type="range"] {
  color: var(--color-red) !important;
}