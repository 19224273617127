.footer {
  position: relative;
  z-index: 1;
  padding: var(--spacing-40) 0 var(--spacing-60);
  background: black;
  color: white;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: var(--color-red);
    content: "";
  }
}

.footer__inner {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.footer__logo {
  display: flex;
  width: 100%;
  padding-bottom: var(--spacing-20);
  border-bottom: 1px solid white;
}

.footer__body {
  display: flex;
  padding: var(--spacing-20) 0;
  border-bottom: 1px solid white;
}

.footer__links {
  flex: 2 0;
  padding: 0;
  list-style: none;

  @include media-lg {
    flex: 1 0;
    column-count: 2;
    column-gap: 20px;
  }

  li {
    line-height: 2rem;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.footer__trustpilot {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  font-size: var(--font-size-10);
  @include media-lg {
    flex: inherit;
    margin-left: 24%;
  }
}

.footer__trustpilot-excellent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-10) 0;
  font-size: var(--font-size-14);
}

.footer__social {
  padding: var(--spacing-20) 0 var(--spacing-100);
  font-size: var(--font-size-12);
}

.footer__social-links {
  display: flex;
  padding: 0;
  margin-top: var(--spacing-20);
  list-style: none;

  li {
    margin-left: 15px;
    line-height: 2rem;

    &:first-of-type {
      margin: 0;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  img {
    height: 20px;
  }
}

.footer {
  color:white!important;
  a {
    color:white!important;
  }
  span {
    color: white!important;
  }
}

.footer__end {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-12);
  color:white!important;


}
