html {
  scroll-behavior: smooth;
}

body {
  @include font-gilroy;
  letter-spacing: 0.5px;
}

[id] {
  scroll-margin-top: 75px;
}