.article-listings {

    &-article-hero {
        @include min-screen($breakpoint-md) {
            min-height: 450px;

            h1 {
                font-size: var(--font-size-50);
            }
        }
    }

    &-article-filter {
        &-wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            padding: var(--spacing-30) 0px;
            gap: var(--spacing-20);
    
            @include min-screen($breakpoint-md) {
                flex-direction: row;
                justify-content: space-between;
                padding: 60px 0px;
            }
        }

        &-form {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: var(--spacing-10);

            @include min-screen($breakpoint-md) {
                width: auto;
                flex-direction: row;
            }

            .select-wrapper {
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    right: 2rem;
                    width: 20px;
                    height: 20px;
                    transform: rotate(90deg) translateX(-50%);
                }
            }
        }
    }

    &-article-select {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 0 var(--spacing-20);
        border: none;
        appearance: none;
        appearance: none;
        appearance: none;
        background: lightgray;
        background: url('SVG REQUIRED') no-repeat right 10px center lightgray;
        background-size: 20px;
        font-size: var(--font-size-20);

        @include min-screen($breakpoint-md) {
            width: 300px;
        }
    }
    
    &-article-button {
        width: 100%;

        @include min-screen($breakpoint-md) {
            width: 300px;
        }
    }
    
    &-articles {
        display: grid;
        margin-bottom: var(--spacing-30);
        gap: var(--spacing-20);
        grid-template-columns: 1fr;

        @include min-screen($breakpoint-md) {
            grid-template-columns: repeat(4, 1fr);
        }
        .card {

            img {
                width: 100%;
                margin-bottom: var(--spacing-20);
                height: 185px;
                object-fit: cover;
            }
    
            .card-content {
                display: flex;
                flex-direction: column;
                margin-bottom: var(--spacing-20);
                gap: var(--spacing-20);
            }
    
            .card-title a {
                color: black;
                font-size: var(--font-size-20);
                text-decoration: none;
            }
    
            .card-footer a {
                color: #ee0a51;
                font-size: var(--font-size-20);
                font-weight: 800;
                text-decoration: none;
            }

            &.featured {
                grid-column: 1 / -1;

                display: grid;
                gap: var(--spacing-20);
                grid-template-columns: 1fr;
                min-height: 50vh;
        
                @include min-screen($breakpoint-md) {
                    grid-template-columns: repeat(4, 1fr);
                }

                .card-image {
                    grid-column: 1 / -1;

                    @include min-screen($breakpoint-md) {
                        grid-column: 1 / 4;
                    }

                    img {
                        margin-bottom: 0;
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .card-content {
                    grid-column: 1 / -1;

                    @include min-screen($breakpoint-md) {
                        grid-column: 4 / 5;
                    }

                    h2 a {
                        font-size: var(--font-size-40);
                    }
                }
            }
        }

        
    }
    
    &-pagination {

        nav {
            display: flex;
            width: 100%;
            padding: var(--spacing-40) 0px;
        }

        ul {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding-left: 0;
            font-size: var(--font-size-20);
            font-weight: bold;
            list-style: none;

            a {
                color: black;
                list-style: none;
            }
        }
    }
}