@include font-face(
  'Gilroy',
  '../fonts/Gilroy-Light',
  300,
  null,
  woff2 woff,
  'Gilroy'
);
@include font-face(
  'Gilroy',
  '../fonts/Gilroy-Regular',
  400,
  null,
  woff2 woff,
  'Gilroy'
);
@include font-face(
  'Gilroy',
  '../fonts/Gilroy-SemiBold',
  700,
  null,
  woff2 woff,
  'Gilroy'
);

$font-sizes: (
  // 8px
  '8': 0.5rem,
  // 10px
  '10': 0.625rem,
  // 12px
  '12': 0.75rem,
  // 14px
  '14': 0.875rem,
  // 16px
  '16': 1rem,
  '18': 1.125rem,
  // 20px
  '20': 1.25rem,
  // 25px
  '25': 1.563rem,
  // 30px
  '30': 1.875rem,
  // 35px
  '35': 2.188rem,
  // 40px
  '40': 2.5rem,
  // 50px
  '50': 3.125rem
);
