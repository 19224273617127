.opening-times {
  //
}

.opening-times__times {
  display: flex;
  padding-bottom: var(--spacing-40);

  ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0;
    font-size: var(--font-size-20);
    line-height: 2.5rem;
    list-style: none;

    li {
      display: flex;

      span {
        flex: 1 0;

        &:nth-child(even) {
          font-weight: bold;
        }
      }
    }
  }
}

.opening-times__number {
  margin-bottom: var(--spacing-60);

  p {
    max-width: 240px;
    margin-bottom: var(--spacing-20);
  }

  svg {
    margin-right: var(--spacing-10);
  }

  a {
    display: flex;
    align-items: center;
    color: var(--color-black);
    font-size: var(--font-size-30);
    font-weight: bold;
  }
}
