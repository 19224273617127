.button {
  font-family: "Gilroy", Helvetica Neue, sans-serif;
  @include button-reset;
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;
  height: 60px;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  font-size: var(--font-size-25);
  font-weight: 600;

  .left {
    position: absolute;
    left: 0;
  }

  .right {
    transform: translateX(0px);
    transition: transform 0.2s ease-in-out;
    &.up {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    .right {
      transform: translateX(5px);
      // transition: transform 0.2s ease-in-out;

      &.up {
        transform: rotate(-90deg);
      }
    }
  }

  svg {
    width: 20px;
    margin-left: auto;
  }
}

.button-red {
  width: 100%;
  background: var(--color-red);
  color: white;

  &.--text-center {
    justify-content: center;
    text-align: center;
  }

  svg {
    @include svg-white;
  }

  &:hover {
    background: var(--color-red-hover);
  }
}

.button-yellow {
  width: 100%;
  background: var(--color-yellow);
  color: var(--color-black);

  &.--text-center {
    justify-content: center;
    text-align: center;
  }

  svg {
    @include svg-black;
  }

}


.button-burgundy {
  width: 100%;
  background: #410c28;
  color: white;
  margin-bottom: 30px;

  &:hover {
    background: var(--color-black-hover);
  }

  svg {
    @include svg-white;
  }
}

.button-black,
.button-midnight-run,
.button-asphalt-lined {
  width: 100%;
  color: white;

  &:hover {
    background: var(--color-black-hover);
  }

  &.--text-center {
    justify-content: center;
    text-align: center;
  }

  svg {
    @include svg-white;
  }
}

.button-black {
  background: var(--color-black);
}

.button-midnight-run {
  background-color: var(--color-midnight-run);
}

.button-asphalt-lined {
  background-color: var(--color-asphalt-lined);
}

.button-high-beams {
  width: 100%;
  background: var(--color-high-beams);
  color: black;

  &:hover {
    background: var(--color-high-beams-hover);
  }

  &.--text-center {
    justify-content: center;
    text-align: center;
  }
}

.button-back {
  padding-left: 22px;
  margin-top: 20px;
  color: white;
  font-size: 20px;
}

.button-underline {
  padding: 0;
  margin-top: 20px;
  color: white;
  font-size: 20px;
  text-decoration: underline;
}

.link {
  margin-bottom: var(--spacing-10);
  text-decoration: none;
}

.link-large {
  position: relative;
  display: flex;
  padding-left: 20px;
  color: white;
  font-size: var(--font-size-20);
  font-weight: bold !important;

  &.--light {
    color: white;
  }

  &.--dark {
    color: var(--color-black);
  }

  svg {
    position: absolute;
    top: 6px;
    left: 0px;
    height: 20px;
  }
}

.cta-link{
  color: #ee0a51;
  font-size: var(--font-size-20);
  font-weight: 800;
  text-decoration: none;
}
