.content-block-red__getting-ready-inner {
  position: relative;
  display: flex;
  overflow: hidden;
  width: calc(100% + 40px);
  flex-direction: column;
  padding: 30px;
  margin-left: -20px;
  background: linear-gradient(180deg, #b60547 0%, #72003c 100%);
  background-color: var(--color-red);
  color: white;
  @include media-lg {
    overflow: visible;
    width: 100%;
    padding-bottom: 300px;
    margin: 0;
  }
}

.content-block-red__title {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-40);

  svg {
    height: 220px;
  }
}

.content-block-red__description {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-25);
  font-weight: 400;
}

.content-block-red__four {
  position: absolute;
  right: 0;
  bottom: 0;
}

.content-block-red__bmw {
  position: absolute;
  z-index: 9;
  bottom: -90px;
  left: -40px;
  display: none;
  width: 100%;
  transform: scale(1.5);
  @include media-lg {
    display: block;
  }
}

.content-block-red__car-birdseye {
  position: absolute;
  z-index: 9;
  right: 17px;
  bottom: 0;
  display: none;
  width: 380px;
  transform: rotate(15deg);
  @include media-lg {
    display: block;
  }
}
