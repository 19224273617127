@mixin button-reset {
  border: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(400px) scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1.5);
  }
}
