.indexPage {
  padding: 100px;

  h1 {
    margin-bottom: 60px;
    font-size: 50px;
    font-weight: bold;
  }

  h2 {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 50px;
    list-style: none;

    li {
      a {
        color: black;
        text-decoration: none;
      }
    }
  }
}
