.how-it-works {
  position: relative;
  display: flex;
  overflow: hidden;
  background: linear-gradient(180deg, #c7094a 0%, var(--color-burgundy) 100%);
  background-color: var(--color-red);
  color: white;

  a:not(.btn) {
    color: #fff;
  }
}

.how-it-works__radial-light {
  position: absolute;
  top: -360px;
  right: -120px;
  display: block;
  width: 900px;
  height: 900px;
  animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(circle, rgb(238, 27, 94) 0%, rgba(238, 10, 81, 1) 24%, rgba(246, 40, 46, 0) 64%);
  opacity: 0.8;
  pointer-events: none;
  transform: scale(1.5);
}

.how-it-works__radial-dark {
  position: absolute;
  top: -360px;
  left: -120px;
  display: block;
  width: 810px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(138deg, rgba(71, 2, 38, 0.931) 0%, rgba(94, 6, 38, 0.755) 23%, rgba(246, 40, 46, 0) 68%);
  pointer-events: none;
}

.how-it-works__four {
  position: absolute;
  display: none;

  @include media-lg {
    top: -90px;
    left: 390px;
    display: block;
    width: 510px;
    transform: rotate(15deg);
  }
}

.how-it-works__inner-desktop {
  display: none;
  // flex: 1.5 0;
  flex: 1 0;
  @include media-lg {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.how-it-works__car-desktop {
  position: absolute;
  z-index: 1;
  top: 50%;
  // left: 50%;
  left: 0;
  width: 100%;
  max-width: 465px;
  margin-bottom: var(--spacing-20);
  // transform: translate(-50%, -35%);
  transform: translate(0%, -35%);
}

.how-it-works__inner {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: var(--spacing-40) 0;
  max-width: calc(100vw - 40px);
  @include media-lg {
    padding: var(--spacing-60) 0;
    padding-bottom: 0;
  }
}

.how-it-works__title {
  position: relative;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-40);
}

.how-it-works__tagline {
  position: relative;
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-20);
  font-weight: 400;
  @include media-lg {
    margin-bottom: var(--spacing-60);
  }
}

.how-it-works__list {
  z-index: 3;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.how-it-works__list-item {
  position: relative;
  display: flex;
  padding-left: var(--spacing-60);
  // margin-bottom: var(--spacing-40);
  counter-increment: step;
  font-size: var(--font-size-20);
  margin-bottom: 25px;

  &:before {
    font-size: 40px !important;
    position: absolute;
    top: 50%;
    left: 20px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-red);
    border-radius: 99rem;
    content: counter(step);
    font-size: var(--font-size-20);
    font-weight: 800;
    transform: translate(-50%, -50%);
    padding: 22px;
  }
  @include media-lg {
    padding-left: var(--spacing-80);
    margin-bottom: 50px;

    &:before {
      width: 64px;
      height: 64px;
      border: 4px solid var(--color-red);
      font-size: var(--font-size-30);
    }
  }

  p {
    display: block;
  }

  a {
    color: white !important;
  }
}
.how-it-works__list-item:nth-child(4) {
  &:before {
    content: "";
    background-image: url("data:image/svg+xml,%0A%3Csvg width='42px' height='70px' viewBox='0 0 56 69' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-fi720ywk2j-1' x='0' y='0' width='55.837' height='68.168'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='four'%3E%3Cmask id='mask-fi720ywk2j-2' fill='white'%3E%3Cuse xlink:href='%23path-fi720ywk2j-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_44'%3E%3C/g%3E%3Cg id='Group_82' mask='url(%23mask-fi720ywk2j-2)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg transform='translate(0.019132, 0.000122)' id='Path_29'%3E%3Cpath d='M55.8188682,45.9208778 L44.4548682,45.9208778 L44.4548682,12.8098778 C44.4548682,-0.274122204 27.1328682,-4.9151222 20.5908682,6.4158778 L1.55386816,39.3898778 C-0.518628071,42.9816087 -0.517910833,47.4060788 1.55574978,50.9971376 C3.62941039,54.5881964 7.46108974,56.8004735 11.6078682,56.8008778 L33.5708682,56.8008778 L33.5708682,68.1678778 L44.4548682,68.1678778 L44.4548682,56.8008778 L55.8188682,56.8008778 L55.8188682,45.9208778 Z M33.5708682,15.1798778 L33.5708682,45.9208778 L14.7498682,45.9208778 C13.8426701,45.9208778 13.0043975,45.4368344 12.5506635,44.6512558 C12.0969294,43.8656772 12.0966263,42.8977405 12.5498682,42.1118778 L28.8318682,13.9118778 C29.4069847,12.9170205 30.5783965,12.4322116 31.68836,12.7296696 C32.7983235,13.0271277 33.5703167,14.0327477 33.5708682,15.1818778'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
  }
}

.how-it-works__outro {
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: var(--spacing-20);
  }
}

.how-it-works__tagline-outro {
  font-size: var(--font-size-20);
  font-weight: 400;
}

.how-it-works__cta {
  margin-bottom: var(--spacing-20);
  @include media-lg {
    max-width: 360px;
  }
}
