.hero-simple {
  position: relative;
  z-index: 1;
  display: flex;
  overflow: hidden;
  background: linear-gradient(180deg, var(--color-red) 0%, var(--color-burgundy) 100%);
  background-color: var(--color-red);
  color: white;
 
  &.--white {
    background: white;
    color: var(--color-black);

    .hero-simple__radial-dark,
    .hero-simple__radial-light {
      display: none;
    }

    .content.--white {
      color: var(--color-black);
    }
  }

  &.--light-gradient {
    background-color: var(--color-red);
    background: linear-gradient(180deg, var(--color-red) 0%, var(--color-burgundy) 100%);
    color: #fff;

    .hero-simple__radial-dark,
    .hero-simple__radial-light {
      display: none;
    }
  }

  &.--dark-gradient {
    background-color: var(--color-burgundy);
    background: linear-gradient(180deg,var(--color-burgundy) 0,#191717 100%);
    color: #fff;

    .hero-simple__radial-dark,
    .hero-simple__radial-light {
      display: none;
    }
  }
}

.hero-simple__four {
  position: absolute;
  top: -60px;
  right: -120px;

  @include media-lg {
    top: -210px;
    right: -250px;
    width: 1200px;
  }
}

.hero-simple__map {
  position: absolute;
  top: -60px;
  right: -120px;

  @include media-lg {
    top: -160px;
    right: 0px;
    width: 1000px;
  }
}

.hero-simple__radial-light {
  position: absolute;
  top: -360px;
  right: -120px;
  display: block;
  width: 900px;
  height: 900px;
  animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(circle, rgba(255, 36, 104, 1) 0%, rgba(238, 10, 81, 1) 24%, rgba(246, 40, 46, 0) 64%);
  opacity: 0.8;
  pointer-events: none;
  transform: scale(1.5);
}

.hero-simple__radial-dark {
  position: absolute;

  top: -360px;
  left: -120px;
  display: block;
  width: 810px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(
    138deg,
    rgba(115, 1, 60, 0.8309917717) 0%,
    rgba(143, 17, 64, 0.7553615196) 23%,
    rgba(246, 40, 46, 0) 68%
  );
  pointer-events: none;
}

.hero-simple__inner {
  z-index: 9;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-40) 0;
  min-width: 100%;
  // flex: 1 0;
  column-gap: 50px;
  @include media-lg {
    flex-direction: row;
    padding: var(--spacing-60) 0;
  }
}

.hero-simple__left {
  @include media-lg {
    flex: 1 0;
  }
}

.hero-simple__right {
  position: relative;
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: center;
  @include media-lg {
    flex: 1 0;
  }
}

.hero-simple__valuation {
  display: grid;
  margin-top: var(--spacing-40);

  @include media-md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .button-red {
    @include media-md {
      margin-left: var(--spacing-20);
    }
  }
}

.hero-simple__feature-image {
  display: none;
  width: 100%;
  max-width: 500px;

  /* &.--larger {
    max-width: 600px;
  }

  &.--smaller {
    max-width: 400px;
  } */

  @include media-lg {
    display: flex;
  }
}

.hero-simple-404 {
  font-size: 170px !important;
}

.hero-simple.--single-column.--white {
  .hero-simple__inner {
    padding-bottom: 0;
  }

  #form {
    max-width: 550px;
  }
}