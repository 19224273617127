.faqs {
  z-index: 9;
  display: flex;
  width: 100%;
  flex-direction: column;

  &.--dark {
    margin-bottom: var(--spacing-40);

    .faq {
      color: var(--color-black);

      &:before {
        filter: invert(64%) sepia(89%) saturate(1064%) hue-rotate(334deg) brightness(-2%) contrast(88%);
      }
    }
  }
}

.faq {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: var(--spacing-20);
  border: none;
  margin-left: var(--spacing-30);
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: var(--font-size-25);
  outline: 0;
  text-align: left;

  &:before {
    position: absolute;
    top: 26px;
    left: 0;
    left: -20px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-m1spt7y678-1' x='0' y='0' width='16' height='16'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='plus'%3E%3Cmask id='mask-m1spt7y678-2' fill='white'%3E%3Cuse xlink:href='%23path-m1spt7y678-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_34'%3E%3C/g%3E%3Cg id='Group_56' mask='url(%23mask-m1spt7y678-2)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cline x1='8' y1='0' x2='8' y2='16' id='Line_12'%3E%3C/line%3E%3Cline x1='16' y1='8' x2='0' y2='8' id='Line_13'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
  }

  &.active {
    &:before {
      top: 32.5px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='3px' viewBox='0 0 16 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='minus' transform='translate(0.000000, 1.000000)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cline x1='16' y1='0.5' x2='0' y2='0.5' id='Line_13'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

.faq__panel {
  display: none;
  overflow: hidden;
  flex-direction: column;
  padding: var(--spacing-20);
  margin-left: 30px;
  font-size: var(--font-size-20);

  p {
    margin-bottom: var(--spacing-20);
  }
}

.faq__panel-urls {
  padding: 0 !important;

  columns: 1;
  list-style: none;
  list-style-position: inside; //this is important addition

  @include media-lg {
    columns: 2;
  }

  a {
    white-space: nowrap;
    &:hover {
      color: var(--color-red);
    }
  }
}
