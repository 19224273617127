textarea:focus,
input:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}

.m4ym-form {
  display: flex;
  flex-direction: column;

  .m4ym-field {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--spacing-20);

    &:last-of-type {
      // margin-bottom: 0;
    }

    .m4ym-field-inner {
      display: flex;
      width: 100%;
      flex-direction: column;
      position: relative;
      z-index: 99;

      &.invalid {
        .m4ym-label {
          span {
            display: flex;
          }
        }
      }
    }

    .m4ym-label {
      display: flex;
      align-items: center;
      margin-bottom: var(--spacing-10);
      font-size: var(--font-size-20);
      font-weight: bold;

      &.is-checkbox,
      &.is-radio {
        display: flex;
        align-items: center;

        margin-bottom: 10px;
        cursor: pointer;
        font-size: var(--font-size-16);
        font-weight: normal;

        input {
          margin-right: 10px;
          cursor: pointer;
        }

        a {
          color: white;
        }
      }

      span {
        display: none;
        margin-left: auto;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .m4ym-input {
      display: flex;
      width: 100%;
      padding: var(--spacing-20);
      border: 2px solid white;
      background-color: var(--color-burgundy-dark);
      border-radius: var(--spacing-10);
      color: white;
      font-size: var(--font-size-20);
    }

    .invalid {
      .m4ym-input {
        border: 2px solid var(--color-red);
      }
    }

    .m4ym-input-status {
      display: none!important;
      position: relative;
      display: flex;
      overflow: hidden;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin-top: 50px;
      margin-left: 20px;
      background: white;
      border-radius: 99rem;
      visibility: hidden;

      &.valid {
        visibility: visible;

        &:after {
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='field_confirmed' fill-rule='nonzero'%3E%3Cpath d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z' id='Path' fill='%23FFFFFF'%3E%3C/path%3E%3Cg id='Group' transform='translate(6.192000, 6.704000)' fill='%234DC777'%3E%3Cpath d='M3.62,11.0110009 C2.86727426,11.011584 2.1453514,10.7121623 1.614,10.179 L-4.4408921e-16,8.564 L1.414,7.15 L3.028,8.764 C3.20631322,8.94204316 3.45499352,9.03086747 3.70575366,9.00608191 C3.95651379,8.98129635 4.18299284,8.84550648 4.323,8.636 L10.08,0 L11.744,1.109 L5.988,9.742 C5.5185072,10.4516668 4.7547072,10.9125173 3.908,10.997 C3.81,11.0063333 3.714,11.0110009 3.62,11.0110009 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          color: var(--color-green);
          content: "";
        }
      }

      &.invalid {
        visibility: visible;

        &:after {
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='field_error' fill-rule='nonzero'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='12' cy='12' r='12'%3E%3C/circle%3E%3Cpolygon id='Path' fill='%23EE0A51' points='16.293 17.707 6.293 7.707 7.707 6.293 17.707 16.293'%3E%3C/polygon%3E%3Cpolygon id='Path' fill='%23EE0A51' points='7.707 17.707 6.293 16.293 16.293 6.293 17.707 7.707'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          color: var(--color-red);
          content: "";
        }
      }
    }
  }

  .button {
    margin-bottom: var(--spacing-30);
  }
}

.form-control--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"],
input[type="radio"] {
  display: grid;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border: 2px solid white;
  margin: 0;
  appearance: none;
  border-radius: 99rem;
  color: white;

  font: inherit;
  place-content: center;
  transform: translateY(-0.075em);
}

input[type="checkbox"]::before,
input[type="radio"]::before {
  width: 18px;
  height: 18px;
  background-color: CanvasText;
  box-shadow: inset 1em 1em white;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  content: "";
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  // outline: max(2px, 0.15em) solid white;
  // outline-offset: max(2px, 0.15em);
}
/* 
input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
} */
