* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: var(--color-red);
}

body {
  -webkit-font-smoothing: antialiased;
}

main {
  position: relative;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  // pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  z-index: 1;
}

strong {
  font-weight: 800;
}

.content {
  color: var(--color-black);

  &.--white {
    color: white;
    a {
      color: var(--color-black);
    }
  }

  h1 {
    margin-bottom: var(--spacing-30);
    font-size: var(--font-size-40);
  }

  h2 {
    margin-bottom: var(--spacing-30);
    font-size: var(--font-size-40);
  }

  h3 {
    margin-bottom: var(--spacing-30);
    font-size: var(--font-size-25);
  }

  p {
    margin-bottom: var(--spacing-20);
    font-size: var(--font-size-20);
  }

  a {
    color: var(--color-black);

    &:hover {
      color: var(--color-red);
    }
  }

  // add spacing to last paragraph where title meets

  p + h1,
  p + h2,
  p + h3 {
    margin-top: 50px;
  }

  ul,
  ol {
    padding: 0 15px;
    margin-bottom: var(--spacing-30);
    font-size: 20px;
    line-height: 40px;
  }

  &.--bg-red {
    padding: var(--spacing-20);
  }

  .value-wrap {
    display: flex;
    align-items: baseline;
  }
  .value {
    margin-bottom: var(--spacing-30);
    font-size: var(--font-size-50);
    margin-right: 1rem;
  }
}
