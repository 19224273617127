@mixin font-family-sans {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
@mixin font-gilroy {
  font-family: 'Gilroy', Helvetica Neue, sans-serif;
}
// https://codepen.io/sosuke/pen/Pjoqqp
@mixin svg-white {
  filter: invert(88%) sepia(99%) saturate(3%) hue-rotate(260deg)
    brightness(110%) contrast(97%);
}
@mixin svg-black {
  filter: invert(4%) sepia(3%) saturate(914%) hue-rotate(314deg) brightness(98%)
    contrast(88%);
}

@mixin button-reset {
  border: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  text-decoration: none;
  user-select: none;
}

@mixin animated {
  transition-duration: 0.35s;
  transition-property: opacity, color, background-color, transform;
  transition-timing-function: ease-out;

  &:hover {
    transition: none;
  }
}

@mixin hover-default {
  @include animated;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

@mixin input-reset {
  border: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
