.valuation-appointments {
  padding: var(--spacing-60) 0;
  padding-bottom: 0;
  background: var(--color-black);
  color: white;

  @include media-lg {
    padding: var(--spacing-60) 0;
  }
}

.valuation-appointments__inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include media-lg {
    flex-direction: row;
    column-gap: 200px;
  }
}

.valuation-appointments__title {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-40);

  svg {
    height: 220px;
  }
}

.valuation-appointments__description {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-25);
  font-weight: 400;
}

.valuation-appointments__getting-ready {
  flex: 1 0;
}

.valuation-appointments__how {
  flex: 1 0;
  margin-bottom: var(--spacing-30);
  @include media-lg {
    margin-bottom: 0;
  }
}

.valuation-appointments__how-inner {
  // max-width: 100%;
  max-width: 360px;
}

.valuation-appointments__list {
  display: flex;

  max-width: 100%;
  flex-direction: column;
  @include media-lg {
    // max-width: 370px;
  }
}

.valuation-appointments__list-item {
  position: relative;
  display: flex;
  padding-left: var(--spacing-40);
  margin-bottom: var(--spacing-40);
  counter-increment: step;
  font-size: var(--font-size-20);

  &:before {
    position: absolute;
    left: 0;
    width: 28px;
    height: 26px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-257' transform='translate(1.000000, 1.000000)'%3E%3Cg id='Ellipse_4'%3E%3Ccircle id='Oval' cx='12' cy='12' r='12'%3E%3C/circle%3E%3Ccircle id='Oval' stroke='%23EE0A51' stroke-width='2' cx='12' cy='12' r='11'%3E%3C/circle%3E%3C/g%3E%3Cg id='Group_200' transform='translate(6.900000, 7.259000)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cpath d='M10.2,0 L4.447,8.636 C4.1409017,9.09481279 3.64531294,9.39231712 3.0964562,9.44673799 C2.54759947,9.50115886 2.00323784,9.30676887 1.613,8.917 L0,7.302' id='Path_43'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    content: '';
  }
}
