.calculating {
  //
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #b60547 0%, #72003c 100%);
  background-color: var(--color-red);
  color: white;
  // pointer-events: none;
}
.calculating__logo {
  width: 100%;
  max-width: 230px;
  min-height: 60px;
  @include media-lg {
    max-width: 400px;
  }
}

.calculating__four {
  position: absolute;
  z-index: 1;
  top: -60px;
  right: -120px;

  @include media-lg {
    top: -140px;
    width: 1100px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}

.calculating__radial-light {
  position: absolute;
  z-index: 2;
  top: -360px;
  // right: -120px;
  right: 160px;
  display: block;
  width: 900px;
  height: 900px;
  // animation: fadeInRight 0.7s ease-in-out;
  background: rgb(255, 36, 104);
  background: radial-gradient(circle, rgba(255, 36, 104, 1) 0%, rgba(238, 10, 81, 1) 24%, rgba(246, 40, 46, 0) 64%);
  opacity: 0.6;
  pointer-events: none;
  // transform: scale(1.5);
}

.calculating__radial-dark {
  position: absolute;

  top: -360px;
  left: -120px;
  display: block;
  width: 810px;
  height: 900px;
  background: rgb(115, 1, 60);
  background: linear-gradient(
    138deg,
    rgba(115, 1, 60, 0.8309917717) 0%,
    rgba(143, 17, 64, 0.7553615196) 23%,
    rgba(246, 40, 46, 0) 68%
  );
  // pointer-events: none;
}

.container-calc {
  max-width: 455px;
  padding: 50px 20px;
}

.calculating__inner {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  // padding: 100px 0px;
  margin: 0 auto;
}

.calculating__countdown {
  height: 150px;
  padding: 14px 0;

  // padding: var(--spacing-80) 0;
  // margin-bottom: var(--spacing-60);

  @include media-md {
    height: 210px;
    padding: var(--spacing-80) 0;
    margin-bottom: var(--spacing-60);
  }
}

.calculating__countdown-inner {
  position: absolute;
  right: 0;
  left: 0;
  width: 124px;
  height: 124px;
  border: 6px solid var(--color-red);
  margin: 0 auto;
  border-radius: 99rem;
}

.animate .calculating__title {
  animation: animation__calculating__checklist 1s forwards;
  animation-delay: 0.5s;
  font-size: var(--font-size-40);
  opacity: 0;
}

.animate .calculating__checklist {
  padding: 0;
  margin: var(--spacing-40) 0;
  margin-bottom: var(--spacing-60);
  font-size: var(--font-size-20);
  line-height: 3.5rem;
  list-style: none;
  // margin-top: 10px;;

  @include media-md {
    margin-top: 10px;;
  }

  li {
    line-height: 1.5;
    margin-top: 10px;

    position: relative;
    padding-left: 45px;
    opacity: 0;

    @include media-sm {
      line-height: 2;
    }

    @include media-md {

      margin-top: 18px;
    }

    &:before {
      position: absolute;
      top: 7px;
      left: 0;
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='32px' height='32px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='tick_WO' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M16,1.99994486 C10.3372147,1.99959139 5.23184236,5.41058671 3.06473297,10.6422952 C0.897623572,15.8740038 2.09560181,21.8960104 6.1,25.9 C11.567619,31.367619 20.432381,31.367619 25.9,25.9 C31.367619,20.432381 31.367619,11.567619 25.9,6.1 C23.2800586,3.46592204 19.7151515,1.98954638 16,1.99994486 M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z' id='Shape'%3E%3C/path%3E%3Cg id='Group' transform='translate(7.939000, 8.682000)'%3E%3Cpath d='M5.062,15.3040007 C3.99556026,15.3046451 2.97281223,14.8803594 2.22,14.125 L0,11.91 L2.122,9.789 L4.337,12.004 C4.55443746,12.2212233 4.8578052,12.3295268 5.16364872,12.2991168 C5.46949224,12.2687067 5.74559393,12.1027866 5.916,11.847 L13.816,0 L16.316,1.664 L8.416,13.511 C7.74812464,14.5162204 6.66321868,15.1673843 5.462,15.284 C5.32866667,15.2973333 5.19533333,15.3040007 5.062,15.3040007 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      content: "";

      @include media-md {

        top: 7px;
      }
    }

    &:first-of-type {
      animation: animation__calculating__checklist 1s forwards;
      animation-delay: 1s;

      span {
        animation: animation__calculating__checklist 1s forwards;
        animation-delay: 1.5s;
      }
    }

    &:nth-of-type(2) {
      animation: animation__calculating__checklist 1s forwards;
      animation-delay: 2s;

      span {
        animation: animation__calculating__checklist 1s forwards;
        animation-delay: 2.5s;
      }
    }

    &:nth-of-type(3) {
      animation: animation__calculating__checklist 1s forwards;
      animation-delay: 3s;

      span {
        animation: animation__calculating__checklist 1s forwards;
        animation-delay: 3.5s;
      }
    }

    span {
      opacity: 0;
    }
  }
}

@keyframes animation__calculating__checklist {
  100% {
    opacity: 1;
  }
}

.calculating__trustpilot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: white;
  font-size: var(--font-size-20);
}

.calculating__trustpilot-outro {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-size-12);
}

.calculating__trustpilot-svg {
  margin-top: 1px;
  margin-left: 15px;
  transform: scale(1.2);
}

.animate .calculating__number {
  position: relative;
  top: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 70px;
  line-height: 200px;
  text-align: center;

  span {
    position: absolute;
    opacity: 0;
  }
}

@keyframes animation__calculating__number {
  0%,
  10%,
  100% {
    opacity: 0;
    transform: scale(1);
  }

  5% {
    opacity: 0.8;
    transform: scale(1.2);
  }
}
@keyframes animation__calculating__number__last {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  5% {
    opacity: 0.8;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1.2);
  }
}

.animate .calculating__number-0 {
  animation: animation__calculating__number__last 20s infinite;
  animation-delay: 4s;
  top: -127px;
}

.animate .calculating__number-1 {
  margin-top: -82px;
  animation: animation__calculating__number 20s infinite;
  animation-delay: 3s;
}

.animate .calculating__number-2 {
  margin-top: -82px;
  animation: animation__calculating__number 20s infinite;
  animation-delay: 2s;
}

.animate .calculating__number-3 {
  margin-top: -82px;
  animation: animation__calculating__number 20s infinite;
  animation-delay: 1s;
}

.animate .calculating__number-4 {
  margin-top: -82px;
  position: relative;
  animation: animation__calculating__number 20s infinite;
  animation-delay: 0s;
  font-size: 0;

  &:after {
    position: absolute;
    top: 63px;
    left: -22px;
    width: 130px;
    height: 130px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='42px' height='70px' viewBox='0 0 56 69' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-fi720ywk2j-1' x='0' y='0' width='55.837' height='68.168'%3E%3C/rect%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='four'%3E%3Cmask id='mask-fi720ywk2j-2' fill='white'%3E%3Cuse xlink:href='%23path-fi720ywk2j-1'%3E%3C/use%3E%3C/mask%3E%3Cg id='Rectangle_44'%3E%3C/g%3E%3Cg id='Group_82' mask='url(%23mask-fi720ywk2j-2)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg transform='translate(0.019132, 0.000122)' id='Path_29'%3E%3Cpath d='M55.8188682,45.9208778 L44.4548682,45.9208778 L44.4548682,12.8098778 C44.4548682,-0.274122204 27.1328682,-4.9151222 20.5908682,6.4158778 L1.55386816,39.3898778 C-0.518628071,42.9816087 -0.517910833,47.4060788 1.55574978,50.9971376 C3.62941039,54.5881964 7.46108974,56.8004735 11.6078682,56.8008778 L33.5708682,56.8008778 L33.5708682,68.1678778 L44.4548682,68.1678778 L44.4548682,56.8008778 L55.8188682,56.8008778 L55.8188682,45.9208778 Z M33.5708682,15.1798778 L33.5708682,45.9208778 L14.7498682,45.9208778 C13.8426701,45.9208778 13.0043975,45.4368344 12.5506635,44.6512558 C12.0969294,43.8656772 12.0966263,42.8977405 12.5498682,42.1118778 L28.8318682,13.9118778 C29.4069847,12.9170205 30.5783965,12.4322116 31.68836,12.7296696 C32.7983235,13.0271277 33.5703167,14.0327477 33.5708682,15.1818778'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
  }
}
