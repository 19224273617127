.vehicles-covered {
  position: relative;
  display: flex;
  // overflow: hidden;
  background: linear-gradient(180deg, var(--color-burgundy) 0%, #191717 100%);
  background-color: var(--color-red);
  color: white;
}

.vehicles-covered__radial-dark {
  position: absolute;
  top: -360px;
  left: -120px;
  display: block;
  width: 1400px;
  height: 1400px;
  background: black;
  background: radial-gradient(circle, #6e1d3f 0%, rgba(119, 13, 52, 0.6111038165) 30%, rgba(246, 40, 46, 0) 60%);
  mix-blend-mode: color-dodge;
  opacity: 0.6;
  pointer-events: none;
}

.vehicles-covered__four {
  position: absolute;
  top: 0;
  right: 0px;
  display: block;
  width: 480px;
  opacity: 0.8;
  transform: rotate(124deg);
  @include media-md {
    position: absolute;
    top: 40px;
    right: 140px!important;
    display: block;
    width: 940px;
    opacity: 0.6;
    transform: rotate(124deg);
  }
  @include media-lg {
    right: 400px!important
  }
  /* @include media-md {
    right: 300px;
  }
  @include media-lg {
    right: 400px;
  } */
}

.vehicles-covered__car-birdseye {
  position: absolute;
  top: 60px;
  right: -49px;
  width: 262px;
  transform: rotate(4deg);
  @include media-md {
    position: absolute;
    top: 172px;
    right: 13px!important;
    width: 590px;
    transform: rotate(4deg);
  }
  /* @include media-md {
    right: 200px;
  }
  @include media-lg {
    right: 280px;
  } */ 
  @include media-lg {
   
    right: 322px!important
  }
}

.vehicles-covered__inner {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding: var(--spacing-40) 0;
  @include media-lg {
    max-width: 33%;
    padding: var(--spacing-60) 0;
  }
}

.vehicles-covered__header {
  width: 50%;
  @include media-lg {
    width: 100%;
  }
}

.vehicles-covered__title {
  position: relative;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-40);
}

.vehicles-covered__tagline {
  position: relative;
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-20);
  font-weight: 400;
  @include media-lg {
    margin-bottom: var(--spacing-60);
  }
}

.vehicles-covered__slider {
  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-60);
}

.vehicles-covered__tagline-outro {
  z-index: 1;
  margin-bottom: var(--spacing-20);
  font-size: var(--font-size-20);
  font-weight: 400;
}

.vehicles-covered__cta {
  width: 100%;
  margin-bottom: var(--spacing-20);
  @include media-lg {
    margin-bottom: var(--spacing-40);
  }
}

.image-frame__alt-caption {
  margin-bottom: var(--spacing-40);
  font-size: var(--font-size-16);
  color: #fff !important;
  margin-top: -60px;
  margin-left: 15px;
  font-weight: 600;
}
