.trustpilot-head {
 position:fixed;
 width: 100%;
 z-index: 10;
}

.trustpilot-head__header {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: var(--color-trustpilot);
  color: white;
  font-size: var(--font-size-12);
}

.trustpilot-head__subheader {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-12);
  margin-top: 50px;

  strong {
    margin: 0 5px;
  }
}
