.area-by-region {
  background: var(--color-black);
  color: white;
}

.area-by-region__inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;

  @include media-lg {
    flex-direction: row;
    padding: var(--spacing-60) 0;
    column-gap: 200px;
  }
}

.area-by-region__left {
  flex: 1 0;
  flex-direction: column;
  padding: var(--spacing-40) 0;

  @include media-lg {
    display: flex;
    padding: 0;
  }
}

.area-by-region__right {
  flex: 1 0;
}

.area-by-region__title {
  margin-bottom: var(--spacing-30);
  font-size: var(--font-size-40);
}
